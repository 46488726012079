import React from 'react';
import CategoriesStyle from './CategoriesStyle';

import ImgA from '../../assets/images/kategoria-A.png';
import ImgB from '../../assets/images/kategoria-B.png';
import ImgC from '../../assets/images/kategoria-C.png';
import ImgD from '../../assets/images/kategoria-D.png';


const Categories = ({ refCategories }) => {

    

    const data = [
        {
            letter: 'A',
            content: 'Jest to najwyższa klasa produktu. W tej grupie znajdują się oryginalne tonery w opakowaniach, które nigdy nie były otwierane. Co ważne, produkty z kategorii A nie mogą mieć żadnych uszkodzeń.',
            image: ImgA
        },
        {
            letter: 'B',
            content: 'Znajdują się tutaj produkty oryginalne, jednak z widocznymi lekkimi defektami na opakowaniu. Mogą to być między innymi zadrapania i rysy, lekkie przygniecenia, napisy czy ślady po taśmach. Istotne jest również to, aby pudełko nie było otwierane.',
            image: ImgB
        },
        {
            letter: 'C',
            content: 'To również kategoria, w której znajdują się wyłącznie produkty oryginalne, natomiast w tym przypadku pudełko może być otwarte. Aby jednak zasobnik był zaliczony do tej grupy, folia, w którą jest opakowany, musi być oryginalnie i szczelnie zamknięta.',
            image: ImgC
        },
        {
            letter: 'D',
            content: 'Ostatnia kategoria produktów przyjmowanych w naszym skupie tonerów. Produkty muszą być oryginalne, jednak mogą być dostarczone w otwartym pudełku. Dopuszczalna jest również otwarta folia, jednak plomba kasety nie może być w żaden sposób naruszona.',
            image: ImgD
        },
    ]

    return (
        <CategoriesStyle>
            <div className="contentWidth" ref={refCategories}>
                {data.map((obj,index) => (
                    <div className="category" key={`category_${index}`}>
                        <div className="main">
                            <label>{obj.letter}</label>
                            <p>{obj.content}</p>
                        </div>
                        <div className="image">
                            <img src={obj.image} alt="Sprzedaj toner" title="Sprzedaj toner" />
                        </div>
                    </div>
                ))}
            </div>
        </CategoriesStyle>
    );
}

export default Categories;