import React , { useRef, useState, useEffect } from "react";
import BugsnagPluginReact from '@bugsnag/plugin-react'
import Bugsnag from '@bugsnag/js'

// import { useQueryParam, StringParam } from "use-query-params";

import UnderHeader from '../sections/UnderHeader/UnderHeader';
import Categories from '../sections/Categories/Categories';
import Template from "../components/Template/Template";
import Opinions from '../sections/Opinions/Opinions';
import Process from "../sections/Process/Process";
import Steps from '../sections/Steps/Steps';
import Logos from '../sections/Logos/Logos';
import Icons from '../sections/Icons/Icons';
import Info from '../sections/Info/Info';
import IndexStyle from './indexStyle';
import Faq from '../sections/Faq/Faq';

Bugsnag.start({
    apiKey: '8fb0db4f14efb74b8a42f4340b1f21f0',
    plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

const IndexPage = () => {

    const refForms = useRef();
    const refFaq = useRef();
    const [showForm,setShowForm] = useState(0);
    const refCategories = useRef();

    useEffect(() => {
        const hashValue = window.location.hash.replace(/^#/, '');
        if (hashValue){
            if (hashValue === 'pSell'){
                setShowForm(1);
            } else {
                setTimeout(() => {
                    slideTo(hashValue);
                },300);
            }
        }
    },[]);

    const slideTo = slide => {
        if (slide === 'categories'){
            refCategories.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (slide === 'forms'){
            refForms.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (slide === 'faq'){
            refFaq.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <ErrorBoundary>
            <Template path="/" showForm={showForm} refCategories={refCategories} refFaq={refFaq} refForms={refForms}>

                <IndexStyle id="wrapper">

                    <UnderHeader setShowForm={setShowForm} refForms={refForms} />
                    <Steps setShowForm={setShowForm} />
                    <Info />
                    <Categories refCategories={refCategories} />
                    <Logos />
                    <Icons />
                    {/* <Faq refFaq={refFaq} /> */}
                    <Opinions />
                    <Process />

                </IndexStyle>

            </Template>
        </ErrorBoundary>
    )
}

export default IndexPage
