import styled from 'styled-components';
import { viewMobile, viewSd } from '../../actions/variables';

const ProcessStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0px 20px;
    h6{
        text-align: center;
        margin: 0px;
        padding: 74px 0px;
        font-weight: 700;
        font-size: 34px;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    .urls{
        ul{
            display: block;
            list-style: none;
            margin: 20px auto;
            text-align: center;
            li{
                display: inline-block;
                padding: 10px;
                a{
                    color: grey;
                    text-decoration: none;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    @media (max-width: ${viewSd}px){
        .contentWidth{
            h6{
                padding: 50px 0px;
            }
    }
    @media (max-width: ${viewMobile}px){
        .contentWidth{
            h6{
                padding: 35px 0px;
            }
        }
    }
`;	

export default ProcessStyle;