import styled from 'styled-components';
import { viewHd , viewSd , viewMobile } from '../../actions/variables';

const OpinionsStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    .contentWidth{
        header{
            text-align: center;
            margin: 0px;
            padding:  74px 0px;
            font-weight: 700;
            font-size: 34px;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
        .opinions{
            display: flex;
            flex-direction: row;
            .opinion{
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                background-color: #f2f0f1;
                width: 25%;
                &:nth-child(1){
                    margin-left: 0px;
                }
                .image{
                    height: 170px;
                    background-position: top center;
                    background-size: cover;
                    img{
                        width: 100%;
                        height: 180px;
                        display: none;
                    }
                }
                .mainContent{
                    flex: 1;
                    padding: 20px 10px 10px 10px;
                    display: flex;
                    flex-direction: column;
                    /* justify-content: flex-start; */
                    .header{
                        flex: 1;
                        position: relative;
                        label{
                            font-size: 36px;
                            text-transform: uppercase;
                            font-weight: 700;
                            display: block;
                            text-overflow: ellipsis;
                            overflow: hidden; 
                            width: calc(100% - 87px); 
                            white-space: nowrap;
                        }
                        .date{
                            float: right;
                            position: absolute;;
                            top: 10px;
                            right: 10px;
                        }
                        .place{
                            display: block;
                        }
                    }
                    p{
                        font-size: 16px;
                        flex: 1;
                    }
                    .stars{
                        align-self: flex-end;
                        width: 100%;
                        /* flex: 1; */
                        .star{
                            width: 35px;
                            height: 35px;
                            display: inline-block;
                            margin-right: 4px;
                            background-image: url(${props => props.starImageEmpty});
                            &.selected{
                                background-image: url(${props => props.starImage});
                            }
                        }
                    }
                }
            }
        }
    }
    .button{
        text-align: center;
        margin: 20px 0px;
        a{
            display: inline-block;
            outline: 0px;
            font-weight: bold;
            background-color: #9fb334;
            color: white;
            border: 0px;
            border-radius: 30px;
            padding: 15px 30px;
            font-size: 26px;
            text-decoration: none;
            &:hover{
                background-color: #5a6619;
            }
        }
    }
    @media (max-width: ${viewHd}px){
        .contentWidth{
            .opinions{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
                padding: 25px;
                .opinion{
                    width: auto;
                    margin-left: 0px;
                    &:nth-child(1){
                        margin-left: 0px;
                    }
                    .image{
                        height: 220px;
                    }
                    .mainContent{
                        .header{
                            label{
                                font-size: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: ${viewSd}px){
        .contentWidth{
            header{
                font-size: 30px;
            }
            .opinions{
                display: block;
                .opinion{
                    margin-bottom: 10px;
                    .mainContent{
                        .header{
                            label{
                                font-size: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: ${viewMobile}px){
        .contentWidth{
            header{
                font-size: 26px;
            }
        }
    }
`;	

export default OpinionsStyle;