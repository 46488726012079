import React from 'react';
import InfoStyle from './InfoStyle';
import ImgImage from '../../assets/images/sprzedaj-toner-www-full-page-02_21.jpg';

const Info = () => {



    return (
        <InfoStyle>
            <div className="contentWidth">
                <div className="toner">
                    <img src={ImgImage} />
                </div>
                <div className="tonerContent">
                    <h2>Skup pełnych oryginalnych tonerów w Warszawie</h2>
                    <h1>Internetowy skup tonerów wszystkich producentów na rynku.</h1>

                    <p>Wieloletnie doświadczenie i wiedza na temat tych produktów pozwalają nam na zapewnienie profesjonalnej, szybkiej obsługi oraz wsparcia technicznego dla naszych Klientów. Skup tonerów pełnych to sposób na odzyskanie pieniędzy za niewykorzystane, zalegające w magazynie materiały eksploatacyjne.</p>
                    <p>Całą procedurę sprzedaży mogą Państwo bez problemu przeprowadzić online. Prowadzimy skup tonerów niewykorzystanych wszytkich marek, m.in. HP, Canon, Minolta czy OKI – należy wypełnić odpowiedni formularz, w którym zawrzeć trzeba dane kontaktowe oraz informacje o produkcie – w odpowiedzi my przekazujemy naszą ofertę. Skup tonerów niewykorzystanych to rozwiązanie korzystne dla Państwa nie tylko z finansowego punktu widzenia. Każda osoba sprzedająca te produkty naszej firmie dokłada również swoją cegiełkę do ochrony środowiska.</p>
                    <p>Dbamy o jak najatrakcyjniejsze ceny, oferując także liczne promocje. Przy odbieraniu odpadów gwarantujemy nie tylko kompleksową obsługę.</p>

                </div>
            </div>
        </InfoStyle>
    );
}
export default Info;