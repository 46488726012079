import React from 'react';
import UnderHeaderStyle from './UnderHeaderStyle';
import FormBox from './FormBox/FormBox';

const UnderHeader = ({ setShowForm , refForms }) => {

    return (
        <UnderHeaderStyle>
            <div className="contentWidth">

                 <div className="content">
                    <h2>Masz niepotrzebne, niewykorzystane tonery, które tylko Ci zalegają?</h2>
                    <p>
                        Dobrze trafiłeś, wypełnij formularz i ciesz się z odzyskania pieniędzy. Bądź EKO, chroń środowisko.
                    </p>
                    <FormBox className="formBox" setShowForm={setShowForm} refForms={refForms} />
                 </div>
            </div>
            <div className="imageBackground"></div>
        </UnderHeaderStyle>
    );
}
export default UnderHeader;