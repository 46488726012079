import React from 'react';

import ProcessStyle from './ProcessStyle';

const Process = () => {

    return (
        <ProcessStyle>
            <div className="contentWidth section-process">
                <h6>Proces skupu tonerów</h6>
                <ul>
                    <li>Jeśli chcą Państwo skorzystać z usług skupu tonerów online, zapraszamy do wypełnienia formularza w odpowiedniej sekcji, w zależności od tego czy posiadają Państwo zbiorniki pełne czy puste.</li>
                    <li>Konieczne jest podanie podstawowych danych kontaktowych, a więc imienia i nazwiska, numeru telefonu oraz adresu zamieszkania lub siedziby Państwa firmy. Prosimy również o podanie dogodnego dla Państwa terminu odbioru przekazywanych produktów.</li>
                    <li>W następnej kolejności podają Państwo podstawowe dane dotyczące tonerów, a więc ich markę, numer katalogowy oraz ilość.</li>
                    <li>Zadbaliśmy również o pole, w którym możliwe jest wpisanie dodatkowych informacji dla odbiorcy, na przykład preferowanej godziny odbioru produktów czy specyficznych cech zbiorników.</li>
                    <li><strong>UWAGA:</strong><br />
                    Przy sprzedaży osobistej sprzedający musi mieć przy sobie dokument potwierdzający tożsamość.</li>
                </ul>

                W przypadku kłopotów z wypełnieniem formularza zapraszamy do kontaktu mailowego lub telefonicznego. Jesteśmy do Państwa dyspozycji.

                <h6>Marki skupowanych tonerów</h6>

                <p>Dowiedz się więcej o skupowanych przez nas markach produktów. <a href="/marki.html">Sprawdź</a></p>

            </div>
        </ProcessStyle>
    );
}
export default Process;